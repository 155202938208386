/* Configuração do layout principal */
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.sections {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Alinha os textos na parte inferior */
  flex-wrap: wrap;
}

.section {
  width: 30%;
  margin-top: 10%;
}

h2 {
  font-size: 1.8rem;
  color: #fff;
  margin-bottom: 10px;
}

ul, ol {
  list-style: none;
  padding-left: 0;
  color: #fff;
}

ul li, ol li {
  margin-bottom: 10px;
}

ul li strong, ol li strong {
  color: rgb(38, 179, 246);
}

/* Estilização do botão */
.button-container {
  position: absolute;
  top: 20px;
  right: 20px;
  text-align: right; /* Alinha o botão à direita */
}

.login-button {
  background-color: #00C4CC;
  width: 300px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #028090;
}

.cadastro-solicitacao {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
}
