* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

html, body {
  font-family: 'Inter', sans-serif;
  background-image: url('./assets/image/fundo.png');
  background-size: cover; /* Para cobrir toda a área */
  background-position: center; /* Centralizar a imagem */
  background-repeat: no-repeat; /* Evitar repetição da imagem */
  width: 100%;
  min-height: 100%;
}
