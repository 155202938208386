/* Main Page Styles */
body {
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html, body {
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.relatorio {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  padding: 32px;
  background-color: #f8fafc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}

.relatorio .title {
  text-align: center;
  color: #333;
  font-size: 28px;
  margin-bottom: 25px;
  font-weight: bold;
}

.relatorio .counts {
  margin-bottom: 20px;
  font-size: 18px;
  color: #333;
  text-align: center;
  background-color: #f7f9fc;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form Styles */
.relatorio .form {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 15px;
  margin-bottom: 30px;
}

.relatorio .form-group {
  display: flex;
  flex-direction: column;
}

.relatorio .form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.relatorio .form input, .form select {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
}

.relatorio .input:focus, .input-select:focus {
  border-color: #3498db;
}

.relatorio .button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.relatorio .button:hover {
  background-color: #0056b3;
}

/* Table Styles */
.relatorio .table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.relatorio th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.relatorio th {
  background-color: #007BFF;
  color: #fff;
  position: sticky;
  top: 0;
}

.relatorio td {
  background-color: #fff;
  text-align: center;
}

/* Loading Spinner */
.relatorio .loading {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #007BFF;
}

.relatorio .spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

/* Improved Sorting Arrows */
.relatorio .sort-arrow {
  visibility: visible;
  margin-left: 5px;
  font-size: 16px;
  color: gray;
  transition: color 0.3s ease, transform 0.3s ease;
}

.relatorio .sort-arrow.active {
  color: black;
  transform: scale(1.2);
}

.relatorio .sort-arrow.asc::after {
  content: '▲';
}

.relatorio .sort-arrow.desc::after {
  content: '▼';
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .form-group, .button {
    width: 100%;
  }
}

/* Material-UI Overrides */
.MuiPaper-root {
  margin-bottom: 24px;
}

.MuiTableContainer-root {
  overflow-x: auto;
}

.MuiTableCell-head {
  font-weight: bold !important;
  white-space: nowrap;
}

.MuiTableCell-root {
  padding: 12px 16px !important;
}

/* Improved Responsive Styles */
@media (max-width: 768px) {
  .relatorio {
    padding: 16px;
  }
  
  .MuiTableCell-root {
    padding: 8px !important;
  }
  
  .MuiTypography-h4 {
    font-size: 1.5rem !important;
  }
}