.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.header-logo{
  width: 33%;
}
.header-email {
  width: 33%;
  display: flex;
  justify-content: center;
}

.header-botao{
  width: 33%;
  display: flex;
  justify-content: flex-end;
}

.header-logo img{
  width: 20%;
  border-radius: 10px;
  margin-left: 1%;
}

.header-logo nav {
  width: 10%;
  
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-right: 30%;
}

nav ul li a {
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 10%;
  transition: background-color 0.3s ease;
}

nav ul li a:hover {
  background-color: #7db4bb;
}

nav ul li .dropdown-menu {
  position: absolute;
  left: 10;
  right: 0.5%;
  display: none;
  flex-direction: column;
  background-color: #01505A;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10%;
}

nav ul li:hover .dropdown-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  top: 6%;
  z-index: 999;
}

nav ul li:hover .dropdown-menu a {
  display: block;
  width: 100%;
}
