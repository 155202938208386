.qrcode {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 0 10px;
  width: 100%;
}


.qrcode h1 {
    color: #80d8e4;
    margin-bottom: 10px;
}

.qrcode h4 {
    color: #80d8e4;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 10px;
}

.qrcode h2 {
    color: #80d8e4;
    justify-content: center;
    margin-top: 5px;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;

}

.login form  .login-input label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #fff;

}
.login form  .login-input input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    height: 49px;
    width: 100%;
    border: 1px solid #01505A;
    border-radius: 12px;
    margin-bottom: 12px;
  
}
  
.login form a {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #fff;
}

.login form .login-button {
  height: 48px;
  background: #01505A;
  border-radius: 12px;
  color: #fff;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 10%;
}
.login form .login-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.login form .login-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px
}

.login form .login-footer p{
  color: #80d8e4;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
}