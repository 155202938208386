/* Base font for the entire application */
:root {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Estilos principais */
.home {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 0 10px;
    width: 100%;
}

.home h1 {
    color: #80d8e4;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    font-family: inherit;
}

/* Menu Admin */
.MenuAdmin {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
}

.MenuAdmin label {
    font-weight: 400;
    font-size: 22px;
    color: #80d8e4;
    font-family: inherit;
}

.MenuAdmin h1 {
    color: #80d8e4;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    font-family: inherit;
}

.MenuAdmin h4 {
    color: #80d8e4;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    font-family: inherit;
}

.MenuAdmin input {
    width: 100%;
    height: 48px; /* Defina uma altura fixa */
    border: 1px solid #5C73DB;
    border-radius: 12px;
    margin: 10px 0; /* Consistência na margem */
    padding: 10px;
    color: #01505A;
    font-size: 16px;
    font-family: inherit;
}

.MenuAdmin .escolha {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.MenuAdmin .escolha .teste {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 10px;
}

.MenuAdmin .lista {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.MenuAdmin .lista span {
    width: 300px;
    font-size: 22px;
}

.MenuAdmin .lista button {
    width: 10%; /* A largura dos botões */
    height: 48px; /* A altura dos botões */
    background: #80d8e4;
    border-radius: 12px;
    color: white;
    border: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    margin: 10px 0; /* Consistência na margem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: all 0.3s ease;
}

.MenuAdmin button:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.05);
}

.MenuAdmin .lista .remover {
    width: 100%; /* A largura dos botões */
    height: 48px; /* A altura dos botões */
    background: #80d8e4;
    border-radius: 12px;
    color: red;
    border: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    margin: 10px 0; /* Consistência na margem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: all 0.3s ease;
}

.MenuAdmin .lista .salvar {
    width: 100%; /* A largura dos botões */
    height: 48px; /* A altura dos botões */
    background: #80d8e4;
    border-radius: 12px;
    color: white;
    border: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    margin: 10px 0; /* Consistência na margem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: all 0.3s ease;
}

.MenuAdmin button {
    width: 100%; /* A largura dos botões */
    height: 48px; /* A altura dos botões */
    background: #80d8e4;
    border-radius: 12px;
    color: white;
    border: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    margin: 10px 0; /* Consistência na margem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: all 0.3s ease;
}

.MenuAdmin button:hover {
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.05);
}

.MenuAdmin .checkbox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.MenuAdmin .checkbox-item label {
    margin-left: 5px;
    margin-right: auto;
    width: 100%;
}

.MenuAdmin .checkbox-item input {
    height: 18px;
    width: 18px;
}

.adicionar {
    display: flex;
    align-items: center;
    gap: 5px;
}

.lista .remover {
    border: none;
    cursor: pointer;
    color: red;
    background: none;
    font-size: 18px;
}

.home-button {
    width: 100%; /* A largura dos botões na seção home */
    height: 48px; /* A altura dos botões */
    background: #01505A;
    border-radius: 12px;
    color: white;
    border: none;
    font-family: inherit;
    font-size: 18px;
    margin: 10px 0; /* Consistência na margem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.home-button:hover {
    opacity: 0.9;
    transform: scale(1.05);
}

.imagemqrcode {
    display: flex;
    text-align: center;
    position: relative;
}

.qrcode img {
    margin-top: 20px;
}

.visitas {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.funcionario {
    border: 4px solid #80d8e4;
    border-radius: 12px;
    margin-top: 5px;
    padding: 10px;
}

.escolha {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
}

.dias-semana {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.check {
    display: flex;
    align-items: center; /* Alinha verticalmente o conteúdo ao centro */
    gap: 5px;
}

.dias-semana input[type="checkbox"] {
    width: 22px;
    height: 22px;
    border: 2px solid #fff;
    border-radius: 4px;
    appearance: none; /* Remove o estilo padrão do checkbox */
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    vertical-align: middle; /* Alinha o checkbox com o texto */
}

.dias-semana input[type="checkbox"]:checked {
    background-color: #80d8e4; /* Cor do botão */
    border-color: #80d8e4;
    color: white; /* Cor do texto interno (se houver) */
}

.dias-semana label {
    font-size: 14px; /* Ajuste do tamanho da fonte do texto */
    color: white; /* Cor do texto */
    cursor: pointer; /* Mostra que o texto também é clicável */
}

.seu-select {
    width: 100%; /* Largura desejada */
    background-color: #fff; /* Cor de fundo */
    border-radius: 12px; /* Arredonde o contêiner */
    border: 1px solid #5C73DB; /* Borda personalizada */
    padding: 8px; /* Espaçamento interno */
  }
  
  /* Estilize o campo de entrada */
  .seu-select__input {
    border: none; /* Remova a borda padrão do campo de entrada */
    outline: none; /* Remova a borda de foco ao clicar */
    padding: 0; /* Remova o preenchimento padrão */
  }
  
  /* Estilize o controle (envolvente do campo de entrada) */
  .seu-select__control {
    border: none; /* Remova a borda padrão do controle */
    box-shadow: none; /* Remova a sombra padrão do controle */
    background-color: transparent; /* Fundo transparente para evitar sobreposição com o contêiner */
    border-radius: 12px; /* Arredonde o controle */
  }
  
  /* Estilize as opções da lista suspensa */
  .seu-select__menu {
    border-radius: 12px; /* Arredonde a lista suspensa */
  }
  
  /* Estilize as opções da lista suspensa quando hover */
  .seu-select__option:hover {
    background-color: #f0f0f0; /* Cor de fundo quando hover */
  }
  
  /* Estilize a seta do indicador */
  .seu-select__indicator-separator {
    display: none; /* Remova o separador da seta do indicador */
  }
  
  /* Estilize o ícone da seta do indicador */
  .seu-select__dropdown-indicator {
    color: #333; /* Cor da seta do indicador */
  }
  
  /* Estilize o texto do placeholder */
  .seu-select__placeholder {
    color: #999; /* Cor do placeholder */
    height: 49px;
  }
  
  /* Estilize a opção selecionada */
  .seu-select__single-value {
    color: #333; /* Cor do texto da opção selecionada */
  }
  
  /* Estilize a seta do indicador quando hover */
  .seu-select__dropdown-indicator:hover {
    color: #555; /* Cor da seta do indicador quando hover */
  }
  .autorizar {
    width: 100vw;
}
  .autorizar .lista{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.MenuAdmin .autorizar .lista button {
    width: 30%; /* A largura dos botões */
    height: 48px; /* A altura dos botões */
    background: #80d8e4;
    border-radius: 12px;
    color: white;
    border: none;
    font-family: inherit;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    margin: 10px 0; /* Consistência na margem */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    transition: all 0.3s ease;
}

.filtro {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    padding: 0 20px;
    width: 100%;
}

.filtro-input {
    max-width: 400px; /* Adjust the value as needed to match other components */
    width: 100%;
    height: 48px;
    border: 1px solid #5C73DB;
    border-radius: 12px;
    padding: 0 15px;
    font-size: 16px;
    color: #01505A;
    font-family: inherit;
    transition: all 0.3s ease;
}

.filtro-input:focus {
    outline: none;
    border-color: #80d8e4;
    box-shadow: 0 0 0 2px rgba(128, 216, 228, 0.2);
}

.filtro-input::placeholder {
    color: #999;
}

/* Responsividade */
@media (max-width: 768px) {
    .MenuAdmin .escolha .teste {
        width: 100%; /* Ajuste da largura para ocupar o espaço disponível */
    }

    .MenuAdmin button, 
    .home-button {
        font-size: 16px; /* Tamanho de fonte menor nos botões */
        height: 44px; /* Altura ajustada para botões */
    }

    .MenuAdmin input {
        font-size: 14px; /* Tamanho de fonte menor nos campos de entrada */
    }

    .MenuAdmin label {
        font-size: 14px; /* Tamanho de fonte menor nos labels */
    }

    .MenuAdmin .checkbox-item label {
        width: auto; /* Ajuste da largura dos labels nas caixas de seleção */
    }
}

/* Responsividade para telas pequenas (até 480px) */
@media (max-width: 480px) {
    .MenuAdmin button, 
    .home-button {
        font-size: 18px; /* Tamanho de fonte ainda menor nos botões */
        height: 36px; /* Altura ajustada para botões em telas muito pequenas */
    }

    .home h1 {
        font-size: 20px; /* Redução do tamanho do título na home */
    }

    .MenuAdmin input {
        font-size: 16px; /* Ajuste no tamanho da fonte dos inputs */
        height: 40px; /* Altura dos campos de entrada reduzida */
    }

    .MenuAdmin label {
        font-size: 16px; /* Tamanho de fonte ajustado para labels */
    }

    .MenuAdmin .checkbox-item label {
        font-size: 12px; /* Tamanho menor para os labels dos checkboxes */
    }

    .MenuAdmin .escolha {
        flex-direction: column; /* Flexbox em coluna para reorganizar os elementos */
        gap: 15px; /* Espaçamento entre os elementos */
    }

    .MenuAdmin .lista {
        flex-direction: column; /* Reorganiza a lista em coluna */
        justify-content: center; /* Centraliza itens em telas menores */
        align-items: center;
    }

    .MenuAdmin .lista span {
        width: 100%; /* Ajusta para ocupar a largura total disponível */
        max-width: 100%; /* Impede que exceda a tela */
    }



    .MenuAdmin .autorizar {
        display: flex;
        flex-direction: column;
    }

    .MenuAdmin .autorizar .lista {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .MenuAdmin .checkbox-item {
        flex-direction: row; /* Alinha itens em linha */
        align-items: center; /* Centraliza verticalmente */
        gap: 10px; /* Espaçamento entre checkbox e label */
    }

    .filtro {
        padding: 0 10px;
    }

    .filtro-input {
        height: 40px;
        font-size: 14px;
    }
}

/* Make sure to import the Inter font in your HTML or via @import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');