   .register {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
  }
  span {
    color: #01505A;
    padding: 1%;
  }
  .formulario {
    display: flex;
    flex-direction: column;
    
  }
  
  .formulario .inputContainer label {
    font-weight: 400;
    font-size: 16px;
    color: #01505A;
    
  }
  
  .formulario .inputContainer input {
    width: 100%;
    height: 49px;
    border: 1px solid #5C73DB;
    border-radius: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #01505A;
  }

  .seu-select {
    width: 100%; /* Largura desejada */
    background-color: #fff; /* Cor de fundo */
    border-radius: 12px; /* Arredonde o contêiner */
    border: 1px solid #5C73DB; /* Borda personalizada */
    padding: 8px; /* Espaçamento interno */
  }
  
  /* Estilize o campo de entrada */
  .seu-select__input {
    border: none; /* Remova a borda padrão do campo de entrada */
    outline: none; /* Remova a borda de foco ao clicar */
    padding: 0; /* Remova o preenchimento padrão */
  }
  
  /* Estilize o controle (envolvente do campo de entrada) */
  .seu-select__control {
    border: none; /* Remova a borda padrão do controle */
    box-shadow: none; /* Remova a sombra padrão do controle */
    background-color: transparent; /* Fundo transparente para evitar sobreposição com o contêiner */
    border-radius: 12px; /* Arredonde o controle */
  }
  
  /* Estilize as opções da lista suspensa */
  .seu-select__menu {
    border-radius: 12px; /* Arredonde a lista suspensa */
  }
  
  /* Estilize as opções da lista suspensa quando hover */
  .seu-select__option:hover {
    background-color: #f0f0f0; /* Cor de fundo quando hover */
  }
  
  /* Estilize a seta do indicador */
  .seu-select__indicator-separator {
    display: none; /* Remova o separador da seta do indicador */
  }
  
  /* Estilize o ícone da seta do indicador */
  .seu-select__dropdown-indicator {
    color: #333; /* Cor da seta do indicador */
  }
  
  /* Estilize o texto do placeholder */
  .seu-select__placeholder {
    color: #999; /* Cor do placeholder */
    height: 49px;
  }
  
  /* Estilize a opção selecionada */
  .seu-select__single-value {
    color: #333; /* Cor do texto da opção selecionada */
  }
  
  /* Estilize a seta do indicador quando hover */
  .seu-select__dropdown-indicator:hover {
    color: #555; /* Cor da seta do indicador quando hover */
  }
  
  .foto {
    cursor: pointer; /* Altera o cursor para indicar que é clicável */
    padding: 8px 12px; /* Adiciona preenchimento para melhor aparência */
    background-color: #fff; /* Cor de fundo */
    color: #fff; /* Cor do texto */
    border-radius: 5px; /* Arredonda as bordas */
    display: inline-block; /* Torna o rótulo um elemento inline-block para ajustar ao texto */
    transition: background-color 0.3s ease; /* Efeito de transição ao passar o mouse */
  }
  
  .foto:hover {
    background-color: rgb(80,170,81); /* Cor de fundo ao passar o mouse */
  }
  .formulario form a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top: 16px;
    padding-bottom: 16px;
    color: #5C73DB;
  }
  
  .formulario .button {
    height: 48px;
    background: #01505A;
    border-radius: 12px;
    color: #fff;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 1%;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .formulario .button:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  

  @media (max-width: 768px) {
    .formulario {
      text-align: center; /* Centralizar o conteúdo para dispositivos móveis */
    }
  
    .formulario form .inputContainer input,
    .seu-select {
      width: 90%; /* Ocupa toda a largura do contêiner */
      margin-top: 5px; /* Espaçamento superior menor para dispositivos móveis */
      margin-bottom: 5px; /* Espaçamento inferior menor para dispositivos móveis */
      /* Outros ajustes de estilo para dispositivos móveis */
    }
  
    /* Outros ajustes de estilo para tela menor */
  }

  